import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

const HeaderContainer = withRouter((props) => <Header {...props} />);
export default class Home extends Component {
	componentDidMount() {
		document.title = 'Ally Infrastructure';
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<HeaderContainer />
				<div className='container' id='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='hero home-hero' />
						</div>
					</div>
					<div className='row margin-bottom-md'>
						<div className='col-12 col-lg-8 offset-lg-2'>
							<h1 className='text-center'>We Are Ally</h1>
							<p className='text-center'>
								Ally Infrastructure specializes in building, acquiring, and
								leasing fiber optic and broadband network infrastructure. We
								work with Developers, HOA Communities, and Internet Service
								Providers to deliver advanced fiber networks and elite internet
								services to new and existing single family, condo, and apartment
								communities.
							</p>
							<p className='text-center'>
								By owning and managing our own fiber infrastructure, we are able
								to empower our communities with multiple service provider
								options, at rates that are significantly lower than retail
								internet pricing.
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='secondary-hero home-secondary-hero'>
								<div className='container'>
									<div className='row no-margin-bottom'>
										<div className='col-12 col-lg-8 offset-lg-2'>
											<p>
												<span className='highlight'>Fiber Freedom</span>
												<br />
												All of us have experienced the frustration of looking
												for high quality, affordable internet service and
												finding that we only have 1 or 2 options in our
												neighborhood. Some of us live in an HOA community that
												is locked into a “bulk” arrangement with an ISP that is
												not delivering service that meets the community’s
												expectations. This frustration is also there for our
												neighbors who build new communities, and find that they
												can’t offer future residents an amazing home technology
												experience because the quality of internet in the area
												is poor.
											</p>
											<p>
												<span className='highlight'>
													Good News: Ally can help.{' '}
												</span>
												<br />
												Here’s how it works: Ally will build or purchase the
												fiber and broadband assets in your community. Our
												infrastructure engineers perform a complete analysis of
												the network, and make all necessary repairs and upgrades
												to ensure the network is capable of delivering wired
												symmetrical Gigabit internet service to each home. Ally
												will then negotiate with local and national ISP’s that
												have passed our stringent quality assurance evaluation,
												and will create agreements with the providers that
												deliver the best value for our communities. If the
												community chooses a bulk arrangement, Ally will ensure
												that homeowners will receive Gigabit speeds at a
												significant discount. If the community prefers a
												competitive environment, Ally will lease network access
												to however many providers the community chooses.
											</p>
										</div>{' '}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-12 col-lg-8 offset-lg-2'>
							<h3 className='text-center lg-margin'>
								Better Choices, Better Prices, Better Service.
								<br />
								That’s what it means to be an Ally.{' '}
							</h3>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import ContactHero from '../ContactHero';

const HeaderContainer = withRouter((props) => <Header {...props} />);
export default class HOACommunities extends Component {
	componentDidMount() {
		document.title = 'HOA Communities | Ally Infrastructure';
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<HeaderContainer />
				<div className='container' id='page-content'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='text-center'>HOA Communities</h3>
							<div className='hero hoa-hero' />
						</div>
					</div>
					<div className='row margin-bottom-lg'>
						<div className='col-12 col-lg-6 order-last order-lg-first'>
							<div alt='HOA Communities' className='side-photo hoa' />
						</div>
						<div className='col-12 col-lg-6'>
							<p className='side-photo-text'>
								Ally partners with your community to create choice, value, and
								the opportunity to own your own network infrastructure. We
								negotiate with your current provider to purchase the network
								assets and remaining bulk contract term. Once the acquisition is
								complete, we form an agreement with a local ISP partner that we
								hold to the highest standards of service quality. Your community
								will receive quality Gigabit internet service at rates lower
								than even the promotional rates of major carriers. <br />
								<br />
								Ally wants to be a part of your community forever, but we also
								value freedom of choice for our partners. After the initial
								agreement term, your community will have the option to purchase
								your network if you so choose. The purchase price will be agreed
								upon up front and will be valid for the life of the network. You
								deserve the right to choose your service provider, and Ally is
								your partner on that path to fiber freedom.
							</p>
						</div>
					</div>
					<div className='row margin-bottom-lg'>
						<div className='col-12'>
							<ContactHero text='Contact us to discuss what Ally can do for your HOA communty.' />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import { Switch, Route, NavLink } from 'react-router-dom';

// import CookieConsent from 'react-cookie-consent';

import PageShell from './PageShell';

import Home from './Home';
import HOACommunities from './HOACommunities';
import NewConstruction from './NewConstruction';
import ServiceProviders from './ServiceProviders';
import ContactUs from './ContactUs';

import Privacy from './Privacy';
import PageNotFound from './PageNotFound';

export default class Main extends React.Component {
	render() {
		return (
			<div>
				<Switch>
					<Route path='/' exact component={PageShell(Home)} />
					<Route path='/home' exact component={PageShell(Home)} />
					<Route path='/hoa' exact component={PageShell(HOACommunities)} />
					<Route
						path='/construction'
						exact
						component={PageShell(NewConstruction)}
					/>
					<Route
						path='/providers'
						exact
						component={PageShell(ServiceProviders)}
					/>
					<Route path='/contact' exact component={PageShell(ContactUs)} />
					<Route path='/privacy' exact component={PageShell(Privacy)} />
					<Route component={PageShell(PageNotFound)} />
				</Switch>
				{/* <CookieConsent
					location='bottom'
					style={{ background: '#101010' }}
					cookieName='cookiesAccepted'
					buttonClasses='btn btn-alt'
					disableStyles={true}
					buttonText='I Understand'
				>
					<p>
						<b>We Value Your Privacy</b>
						<br />
						We use cookies for account authentication and to analyze site usage.
						By clicking "I Understand", you agree to the storing of cookies on
						your device for the described purposes.
						<br />
						<NavLink to='/privacy'>View our Privacy Policy</NavLink>
					</p>
				</CookieConsent> */}
			</div>
		);
	}
}

import React from 'react';
import { NavLink } from 'react-router-dom';

import AllyLogoBW from '../images/ally-logo-bw.png';
import { HamburgerSqueeze } from 'react-animated-burgers';

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false,
			reveal: false,
		};
	}

	handleToggle(e) {
		e.preventDefault();
		this.setState({
			isExpanded: !this.state.isExpanded,
		});
	}
	closeNav(e) {
		this.setState({
			isExpanded: false,
		});
	}
	componentDidMount() {
		document.addEventListener('scroll', () => {
			let contentPos = document
				.querySelectorAll('[id=page-content]')[0]
				.getBoundingClientRect().top;
			let screenWidth = window.screen.width;
			let headerHeight = screenWidth > 768 ? 206 : 53;
			this.setState({ reveal: contentPos - headerHeight < 0 });
		});
	}

	render() {
		const isExpanded = this.state.isExpanded;
		const reveal = this.state.reveal;

		const navItems = [
			{
				path: '/home',
				title: 'Home',
			},
			{
				path: '/hoa',
				title: 'HOA Communities',
			},
			{
				path: '/construction',
				title: 'New Construction',
			},
			{
				path: '/providers',
				title: 'Service Providers',
			},
			{
				path: '/contact',
				title: 'Contact Us',
			},
		];
		let currentPath = this.props.location ? this.props.location.pathname : '';
		return (
			<div>
				<header className={reveal ? 'active' : ''}>
					<div className='container'>
						<div className='row no-margin-bottom'>
							<div className='col-12'>
								<NavLink to='/' className='logo-container'>
									<img
										src={AllyLogoBW}
										alt='Ally Infrastructure'
										className='logo'
									/>
								</NavLink>
								<ul className='nav d-none d-md-inline-block'>
									{navItems.map((navItem, index) => (
										<li key={index}>
											<NavLink
												to={navItem.path}
												className={
													navItem.path === '/home' && currentPath === '/'
														? 'active'
														: null
												}
											>
												{navItem.title}
											</NavLink>
										</li>
									))}
								</ul>
								<div
									className={`d-md-none mobile-nav-container ${
										isExpanded ? 'expand' : ''
									}`}
								>
									<ul className='mobile-nav nav'>
										{navItems.map((navItem, index) => (
											<li key={index}>
												<NavLink
													to={navItem.path}
													className={
														navItem.path === '/home' && currentPath === '/'
															? 'active'
															: null
													}
												>
													{navItem.title}
												</NavLink>
											</li>
										))}
									</ul>
								</div>
								<div className='hamburger d-md-none'>
									<HamburgerSqueeze
										isActive={isExpanded}
										toggleButton={(e) => this.handleToggle(e)}
										buttonColor='rgba(0,0,0,0)'
										barColor='#000000'
									/>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		);
	}
}

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

class PageNotFound extends Component {
	componentDidMount() {
		document.title = 'Page not Found | Ally Infrastructure';
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<Header />
				<div className='container' id='page-content'>
					<div className='row no-margin-bottom'>
						<div className='col-12'>
							<h3 className='text-center'>404 - Page not Found</h3>
							<p className='text-center'>
								The page you were looking for does not exist.
								<br />
								<NavLink to='/'>
									Take Me Home <span>&gt;&gt;</span>
								</NavLink>
							</p>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default PageNotFound;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';

import AllyLogo from '../images/ally-logo.png';
export default class Footer extends Component {
	render() {
		const navItems = [
			{
				path: '/home',
				title: 'Home',
			},
			{
				path: '/hoa',
				title: 'HOA Communities',
			},
			{
				path: '/construction',
				title: 'New Construction',
			},
			{
				path: '/providers',
				title: 'Service Providers',
			},
			{
				path: '/contact',
				title: 'Contact Us',
			},
		];
		return (
			<footer>
				<div className='container'>
					<div className='row no-margin-bottom align-items-end'>
						<div className='col-12'>
							<img src={AllyLogo} alt='Ally Infrastructure' className='logo' />
							<ul className='nav'>
								{navItems.map((navItem, index) => (
									<li key={index}>
										<NavLink to={navItem.path}>{navItem.title}</NavLink>
									</li>
								))}
							</ul>
							<p className='text-center'>
								&copy; <Moment date={new Date()} format='YYYY' /> Ally
								Infrastructure.
							</p>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

import React from 'react';

import { NavLink } from 'react-router-dom';

export default function ContactHero(props) {
	return (
		<div className='secondary-hero contact-secondary-hero'>
			<div className='container'>
				<div className='row'>
					<div className='col-12 col-lg-6 offset-lg-3'>
						<h4 className='text-center'>{props.text}</h4>
					</div>
				</div>
				<div className='row no-margin-bottom'>
					<div className='col-12 col-lg-6 offset-lg-3'>
						<NavLink className='btn btn-center' to='/contact'>
							Let's Talk
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}

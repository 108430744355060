import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import ContactHero from '../ContactHero';

const HeaderContainer = withRouter((props) => <Header {...props} />);
export default class ServiceProviders extends Component {
	componentDidMount() {
		document.title = 'Service Providers | Ally Infrastructure';
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<HeaderContainer />
				<div className='container' id='page-content'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='text-center'>Service Providers</h3>
							<div className='hero providers-hero' />
						</div>
					</div>
					<div className='row margin-bottom-lg'>
						<div className='col-12 col-lg-7'>
							<p className='side-photo-text'>
								Ally offers Service Providers the unique opportunity to access
								new subscribers without having to spend a large amount of
								capital. Building and acquiring new networks can be expensive
								and risky, particularly for independent providers that
								specialize in fiber service. Competition in most areas is
								limited because few companies can afford to wait for the return
								on such a large upfront investment, so many smaller local
								providers are shut out of neighborhoods and buildings. Ally
								evens the playing field for all providers by providing equal
								access to fiber networks for a low monthly access rate per home.
								We’ve provided the pathway for providers to simply install
								equipment and start delivering service right away.
								<br />
								<br />
								If you are a service provider that would like to partner with
								Ally to deliver service on our networks, please reach out below.
								You will receive our Ally Partners assessment program, which we
								ask all potential partners to complete. If your service platform
								meets our requirements, you will be added to our Ally Partners
								program and will be eligible to deliver service on any Ally
								networks within your service footprint. Our network engineering
								team will assist in making sure your installation process is
								smooth, and will provide all necessary network documentation and
								fiber maps.
								<br />
								<br />
								Ally is always looking for new fiber networks, broadband assets,
								and subscriber bases to acquire. If you are a service provider
								and are interested in divesting HOA Community networks, MDU
								building networks, or outside plant built fiber please reach out
								below. Because we lease access to our owned networks, providers
								who sell networks to us often retain access to their subscribers
								as a leasing partner. Ally is currently focused on acquiring
								assets in the Western United States, in communities of any size
							</p>
						</div>
						<div className='col-12 col-lg-5'>
							<div alt='Service Providers' className='side-photo providers' />
						</div>
					</div>
					<div className='row margin-bottom-lg'>
						<div className='col-12'>
							<ContactHero text='Contact us to discuss what Ally can do for your service.' />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import ContactHero from '../ContactHero';

const HeaderContainer = withRouter((props) => <Header {...props} />);
export default class NewConstruction extends Component {
	componentDidMount() {
		document.title = 'New Construction | Ally Infrastructure';
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<HeaderContainer />
				<div className='container' id='page-content'>
					<div className='row'>
						<div className='col-12'>
							<h3 className='text-center'>New Construction</h3>
							<div className='hero construction-hero' />
						</div>
					</div>
					<div className='row margin-bottom-md'>
						<div className='col-12 col-lg-10 offset-lg-1'>
							<p>
								Ally partners with developers to install conduit pathways and
								fiber infrastructure from the community entry point to each
								home. Ally will sign a master agreement to be the owner and
								operator of the fiber network, and will provide all conduit,
								fiber, and network components necessary to deliver Gigabit
								service to all homes at no cost. Once the community is
								constructed, Ally will either negotiate with it’s preferred ISP
								to form a bulk agreement with the HOA, or will offer competition
								by creating network lease agreements with as many providers as
								possible.
							</p>
							<p>
								Ally provides our developer partners with savings on their
								infrastructure budget while delivering extremely high quality
								fiber networks. With increased home values and decreased time on
								the market, our partners enjoy increased ROI and happier
								residents. In addition, Ally offers the opportunity for our
								developer partners to participate in an ongoing revenue share.
								Developers may choose to share in the upfront cost of network
								construction, and will receive a percentage of our network lease
								revenue based on the initial investment. Developers no longer
								have to simply build homes, sell them, and move on. Ally offers
								developers the opportunity to continue generating revenue from
								their projects long after the last home is sold.
							</p>
						</div>
					</div>
					<div className='row margin-bottom-lg'>
						<div className='col-12'>
							<ContactHero text='Contact us to discuss what Ally can do for your construction project.' />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

import React from 'react';
import { withRouter } from 'react-router-dom';

import Main from './Main';

const MainContainer = withRouter((props) => <Main {...props} />);

const App = () => (
	<div>
		<MainContainer />
	</div>
);

export default App;

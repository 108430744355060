import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import ContactForm from './ContactForm';

const HeaderContainer = withRouter((props) => <Header {...props} />);
export default class ContactUs extends Component {
	componentDidMount() {
		document.title = 'Contact Us | Ally Infrastructure';
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<HeaderContainer />
				<div className='container' id='page-content'>
					<div className='row margin-bottom-lg'>
						<div className='col-12'>
							<h3 className='text-center'>Contact Us</h3>
						</div>
						<div className='col-12 col-md-7'>
							<div alt='Contact Us' className='side-photo contact' />
						</div>
						<div className='col-12 col-md-5'>
							<h5>See what Ally can do for you</h5>
							<p className='small'>
								Please fill out the form below and someone from Ally will reach
								out to you within the next 24 hours.
							</p>
							<ContactForm />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

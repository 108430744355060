import React, { Component } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Recaptcha from 'react-recaptcha';

import Processing from '../Processing';
import Fade from 'react-reveal/Fade';

import * as emailjs from 'emailjs-com';

let requiredMessage = 'This field is required.';

const ContactFormSchema = Yup.object().shape({
	firstName: Yup.string().required(requiredMessage),
	lastName: Yup.string().required(requiredMessage),
	email: Yup.string()
		.email('Please enter a valid Email Address')
		.required(requiredMessage),
	phone: Yup.string().required(requiredMessage),
	message: Yup.string().required(requiredMessage),
	recaptchaResponse: Yup.string().required(requiredMessage),
});

export default class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const script = document.createElement('script');
		script.src = 'https://www.google.com/recaptcha/api.js';
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
	}

	render() {
		return (
			<div>
				<Formik
					initialValues={{
						firstName: '',
						lastName: '',
						phone: '',
						email: '',
						message: '',
						recaptchaResponse: '',
					}}
					enableReinitialize={true}
					validationSchema={ContactFormSchema}
					onSubmit={(values, actions) => {
						emailjs
							.send(
								process.env.REACT_APP_EMAILJS_SERVICEID,
								process.env.REACT_APP_EMAILJS_TEMPLATEID,
								{
									name: values.firstName + ' ' + values.lastName,
									email: values.email,
									phone: values.phone,
									message: values.message,
									'g-recaptcha-response': values.recaptchaResponse,
								},
								process.env.REACT_APP_EMAILJS_USERID,
							)
							.then(
								(response) => {
									actions.setSubmitting(false);
									actions.resetForm();
									actions.setStatus({ success: true });
								},
								(err) => {
									actions.setSubmitting(false);
								},
							);
					}}
				>
					{({
						values,
						touched,
						errors,
						isSubmitting = false,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
						status = { success: false },
					}) => (
						<div
							className={
								'form-container' + (status.success === true ? ' success' : '')
							}
						>
							{isSubmitting && (
								<Fade>
									<Processing />
								</Fade>
							)}
							{!isSubmitting && status.success !== true && (
								<Fade>
									<Form className='animate-hide' onSubmit={handleSubmit}>
										<div className='row no-margin-bottom'>
											<div className='col-12'>
												<Field name='firstName'>
													{({ field }) => (
														<div
															className={
																'form-row floating-input' +
																(errors.firstName && touched.firstName
																	? ' error'
																	: '') +
																(isSubmitting ? ' disabled' : '')
															}
														>
															<input
																{...field}
																placeholder='First Name'
																type='text'
																value={values.firstName}
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isSubmitting}
																className={
																	'form-control block' +
																	(errors.firstName && touched.firstName
																		? ' error'
																		: '') +
																	(values.firstName === null ||
																	values.firstName === ''
																		? ' input-empty'
																		: '')
																}
															/>
															<label
																className='float-label '
																htmlFor='firstName'
															>
																First Name
															</label>
															{errors.firstName && touched.firstName && (
																<p className='form-response'>
																	{errors.firstName}
																</p>
															)}
														</div>
													)}
												</Field>
											</div>
											<div className='col-12'>
												<Field name='lastName'>
													{({ field }) => (
														<div
															className={
																'form-row floating-input' +
																(errors.lastName && touched.lastName
																	? ' error'
																	: '') +
																(isSubmitting ? ' disabled' : '')
															}
														>
															<input
																{...field}
																placeholder='Last Name'
																type='text'
																value={values.lastName}
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isSubmitting}
																className={
																	'form-control block' +
																	(errors.lastName && touched.lastName
																		? ' error'
																		: '') +
																	(values.lastName === null ||
																	values.lastName === ''
																		? ' input-empty'
																		: '')
																}
															/>
															<label
																className='float-label '
																htmlFor='lastName'
															>
																Last Name
															</label>
															{errors.lastName && touched.lastName && (
																<p className='form-response'>
																	{errors.lastName}
																</p>
															)}
														</div>
													)}
												</Field>
											</div>
										</div>
										<div className='row no-margin-bottom'>
											<div className='col-12'>
												<Field name='email'>
													{({ field }) => (
														<div
															className={
																'form-row floating-input' +
																(errors.email && touched.email
																	? ' error'
																	: '') +
																(isSubmitting ? ' disabled' : '')
															}
														>
															<input
																{...field}
																placeholder='Email Address'
																type='email'
																value={values.email}
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isSubmitting}
																className={
																	'form-control block' +
																	(errors.email && touched.email
																		? ' error'
																		: '') +
																	(values.email === null || values.email === ''
																		? ' input-empty'
																		: '')
																}
															/>
															<label className='float-label ' htmlFor='email'>
																Email Address
															</label>
															{errors.email && touched.email && (
																<p className='form-response'>{errors.email}</p>
															)}
														</div>
													)}
												</Field>
											</div>
											<div className='col-12'>
												<Field name='phone'>
													{({ field }) => (
														<div
															className={
																'form-row floating-input' +
																(errors.phone && touched.phone
																	? ' error'
																	: '') +
																(isSubmitting ? ' disabled' : '')
															}
														>
															<input
																{...field}
																placeholder='Phone Number'
																type='text'
																value={values.phone}
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isSubmitting}
																className={
																	'form-control block' +
																	(errors.phone && touched.phone
																		? ' error'
																		: '') +
																	(values.phone === null || values.phone === ''
																		? ' input-empty'
																		: '')
																}
															/>
															<label className='float-label ' htmlFor='phone'>
																Phone Number
															</label>
															{errors.phone && touched.phone && (
																<p className='form-response'>{errors.phone}</p>
															)}
														</div>
													)}
												</Field>
											</div>
										</div>
										<div className='row no-margin-bottom'>
											<div className='col-12'>
												<Field name='message'>
													{({ field }) => (
														<div
															className={
																'form-row floating-input' +
																(errors.message && touched.message
																	? ' error'
																	: '') +
																(isSubmitting ? ' disabled' : '')
															}
														>
															<textarea
																{...field}
																placeholder='Message'
																type='text'
																value={values.message}
																onChange={handleChange}
																onBlur={handleBlur}
																disabled={isSubmitting}
																className={
																	'form-control block' +
																	(errors.message && touched.message
																		? ' error '
																		: '') +
																	(values.message === null ||
																	values.message === ''
																		? ' input-empty'
																		: '')
																}
															/>
															<label className='float-label ' htmlFor='message'>
																Message
															</label>
															{errors.message && touched.message && (
																<p className='form-response'>
																	{errors.message}
																</p>
															)}
														</div>
													)}
												</Field>
											</div>
										</div>
										<div className='row no-margin-bottom'>
											<div className='col-12'>
												<div
													className={
														'form-row' +
														(errors.recaptchaResponse &&
														touched.recaptchaResponse
															? ' error '
															: '') +
														(isSubmitting ? ' disabled' : '')
													}
												>
													<Recaptcha
														sitekey={process.env.REACT_APP_SITE_KEY}
														render='explicit'
														theme='light'
														verifyCallback={(response) => {
															setFieldValue('recaptchaResponse', response);
														}}
														onloadCallback={() => {}}
													/>
													{errors.recaptchaResponse &&
														touched.recaptchaResponse && (
															<p className='form-response'>
																{errors.recaptchaResponse}
															</p>
														)}
												</div>
											</div>
										</div>
										<div className='row no-margin-bottom'>
											<div className='col-12'>
												<button
													type='submit'
													className={
														'btn' +
														(!isEmpty(errors) || isEmpty(touched)
															? ' invalid'
															: '')
													}
													disabled={isSubmitting}
												>
													Submit
												</button>
											</div>
										</div>
									</Form>
								</Fade>
							)}
							{!isSubmitting && status.success === true && (
								<div className='inner-container'>
									<Fade>
										<h3 className='text-center'>Thank You</h3>
										<p className='text-center'>
											We have received your message and will contact you as soon
											as possible.
										</p>
									</Fade>
								</div>
							)}
						</div>
					)}
				</Formik>
			</div>
		);
	}
}

import React from 'react';

// import { Fade } from 'react-reveal';

const PageShell = (Page) => {
	return (props) => (
		// <Fade duration={600}>
		<Page {...props} />
		// </Fade>
	);
};

export default PageShell;
